import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import { BrowserRouter as Router, Route, Switch,navigate } from 'react-router-dom';
import CreateEmployee from './CreateEmployee';
import { Button, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import ViewEmployee from './ViewEmployee';

function App({elist}) {
  const clients=elist?elist:[];console.log("app clients are is".concat(clients));
              //const [clients, setClients] = useState([]);
              const [useredit, setUseredit] = useState('');
              const modal = document.getElementById("myModal");
              
              // useEffect(()=>{
              //         async function componentDidMount(){
              //                     const response = await fetch('/employees');
              //                     const emps = await response.json();
              //                       setClients(emps);
              //                   };
              //               componentDidMount();
              //       }, []);

            function showEditModel(props) {
              document.getElementById("myModal").style.display="block";
                    setUseredit(props);
                    console.log("set user".concat(props.name));
              };

            function viewEmployee(props){
              
              window.location.href="employee?id=".concat(props.id);

            }  

    return(
      <>
      <table id="employees">
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Company</th>
          <th>Actions</th>
        </tr>
        
        {  clients.map(
                record=>(
                <tr>
                <td>{record.id}</td>
                <td>{record.name}</td>
                <td>{record.company}</td>
                <td>
                  
                <button className="button" onClick={()=>showEditModel(record)}>Edit</button>
                <button className="button" onClick={()=>viewEmployee(record)}>View</button>
                
                </td>
                </tr> 
                )
              )  
        }
        
      </table>
      <div id="myModal" class="modal">
          <div className="modal-content">
            <span ><p className="close"  onClick={()=>{modal.style.display="none";}}>&times;</p></span>
            {useredit?(<CreateEmployee user={useredit} type='edit'/>):''}
          </div>
      </div>
      </>
    );
 }
export default App;
