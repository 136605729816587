 import { useState } from "react";
 import './Timesheet.css';
 import Attendance from "./Attendance";

function Timesheet({elist}){

    const[date,setDate]=useState("");




    const handleChange=(event)=>{
        const selectedDate =event.target.value;
        setDate(selectedDate);
    }

    
    return (
                <>
                        <h1>Attendance</h1>
                        Select date: <input type="date" name="date" value={date} onChange={handleChange} max={new Date().toISOString().slice(0, 10)}></input>
                        {(date)?(<Attendance elist={elist} date={date}/>):<h1>Select date to continue</h1>}
                </>
            );

}

export default Timesheet;