import { useEffect, useState } from "react";

function Attendance({elist, date}){
        const tempdate=date;
    const [localdate, setLocaldate] = useState("");
    const [attendance, setAttendance] = useState(new Map());
    const [overtime, setOvertime] = useState(new Map());
    var nextAttendance =new Map();
    var nextOvertime =new Map();
        //const attendance =new Map();
        //const overtime =new Map();
        
        useEffect(()=>{
            setAttendance(new Map());
            setOvertime(new Map());
            setLocaldate(tempdate);
            setAttendance(new Map());
            setOvertime(new Map());
            console.log("date changed and attendance set");
            console.log(JSON.stringify([...overtime]));
            setPresents();
        }, [date]);

       
    const saveAttendance=()=>{
       
        console.log(JSON.stringify([...attendance]));
        console.log(JSON.stringify([...overtime]));

    }


    function setPresents(){
        //write API response code here instead of mock data
        let tempresents =new Map();
        tempresents.set("heloo", [true,'8']);
        tempresents.set("prashanth vadla", true);
        tempresents.set("jyothi vadla naroju", true);

        let tempovertime =new Map();
        tempovertime.set("heloo", 2);
        tempovertime.set("prashanth vadla", 6);
        tempovertime.set("jyothi vadla naroju", 18.5);
        
        setAttendance(tempresents);
        setOvertime(tempovertime);
       // console.log("set default values "+JSON.stringify(tempresents));
    }

    

    const handleChange = (event) => {
        console.log(event.target.type);
        switch(event.target.type){
            
            case "checkbox":
              nextAttendance=new Map([...attendance, ...nextAttendance]);
                
                if(nextAttendance.get(event.target.name)===true){
                    nextAttendance.set((event.target.name),false)
                    
                }else{
                    nextAttendance.set((event.target.name),true);
                    
                }
                setAttendance(nextAttendance);
              
            break;

            case "number":
            nextOvertime= new Map([...overtime, nextOvertime]);
            nextOvertime.set((event.target.name), event.target.value);
            setOvertime(nextOvertime);
                
            break;

            default:

            

        }

        
        
       console.log(event.target.name+"-->present? "+attendance.get(event.target.name));
       //console.log(JSON.stringify([...attendance]));
       
      }

    
       


    return(
        <>

        <h5>Selected date is: {date}</h5>
        <table id="employees">
        <tr>
        <th>Id</th>
        <th>Name</th>
        <th>Present on {date}?</th>
        <th>OT</th>
        </tr>

        
        {  elist.map(
                record=>(
                <tr>
                <td>{record.id}</td>
                <td>{record.name}</td>
                <td><input type="checkbox" class="checks" name={record.name} id={record.id} checked={attendance.get(record.name)} onClick={handleChange}></input></td>
                <td><input type="number"  max="250" maxLength="5" name={record.name} id={record.id} value={overtime.get(record.name)||""} onInput={(event)=>{if (event.target.value.length > event.target.maxLength) event.target.value = event.target.value.slice(0, event.target.maxLength)}} onChange={handleChange} ></input></td>
                </tr> 
                )
            )  
        }

    </table>
    <input type="submit" value="Submit" onClick={saveAttendance}></input>
    </>
    );
    
}
export default Attendance;