const Logout=  ()=> {

                
                window.location.href =  "/logout";
                sessionStorage.clear(); 
                window.location.href =  "/";

    return (
  
    <h2>You have successfully logged out.</h2>
   
    );
    
}

export default Logout;