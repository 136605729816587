import React, { Component } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import {Navbar, NavbarBrand} from 'reactstrap';
import App from './App';
import { useState,useEffect } from 'react';
import Timesheet from './Timesheet';
import Login from './Login';

function Dashboard(){

    const [clients, setClients] = useState([]);
    const [view, setView]=useState("list");
    
    useEffect(()=>{
        async function componentDidMount()
                {   
                    const response = await fetch('/employees',
                                            {
                                                method: "GET",
                                                headers:{
                                                        "Content-Type": "application/json",
                                                        "Accept":"*/*",
                                                        "Authorization":  "Bearer "+sessionStorage.getItem("token")
                                                        }
                                            }
                                        );
                    const emps = await response.json();
                    setClients(emps);
                };
              componentDidMount();
      }, []);

      const toggleView=()=>{
        if(view=="list"){
            setView("attendance");
            document.getElementsByClassName("header-button")[0].innerHTML="Employee List";
        }else{
            setView("list");
            document.getElementsByClassName("header-button")[0].innerHTML="Attendance";
        }
      }

      
      if(sessionStorage.getItem("session")!="true"){
        window.location.href =  "/login";
        return <><h2>Please login...</h2></>;

      }else

        return(
<>

{/* <Container fluid>
                    <Button color="link"><Link to="/CreateEmployee">Onboard Employee</Link></Button>                    
                </Container> */}
                <a href ="/CreateEmployee">Onboard Employee</a>
                <button class="header-button" name="view" onClick={toggleView}>Attendance</button>
                <button class="header-button" name="view" onClick={toggleView}>Add Deductions</button>
                <input class="header" type="Search" name="searchbox" placeholder="Search..."></input>
                
                    
                
                {
                    
                (view=='list')?(<App elist={clients}/>):(<Timesheet elist={clients}/>)
                
                }
           </>
        ); 
}
export default Dashboard;