import { Outlet, Link } from "react-router-dom";
import {Navbar, NavbarBrand} from 'reactstrap';
import './AuthLayout.css';

const AuthLayout = () => {
  return (
    <>
      <ul class="navigation">
            <li class="navigation-list"><a href="/dashboard">Home</a></li>
            {/* <li class="navigation-list"><a href="/blogs">Blogs</a></li>
            <li class="navigation-list"><a href="/contact">Contact</a></li> */}
            <li class="navigation-list"><a href="/logout">Logout</a></li>
     </ul>
      <Outlet />
    </>
  )
};

export default AuthLayout;