import React, {Component} from 'react';
import {Navbar, NavbarBrand} from 'reactstrap';
import {Link, Navigate} from 'react-router-dom';
import './CreateEmployee.css';
import {useState, useEffect} from 'react';

function CreateEmployee({user, type})  {
    console.log("============create============".concat(user?(user.name):("notset")));
    const [employee,setEmployee] =useState({});
    const [emptype,setEmptype] =useState("");
       
    useEffect(()=>{ setEmployee(user);}
                ,[user]
            );

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setEmployee(values => ({...values, [name]: value}));
      }
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(JSON.stringify(employee));

        
        
        fetch('/employees/add',{
            method: "POST",
            body:JSON.stringify(employee),
            headers:{
                "Content-Type": "application/json",
                "Accept":"*/*",
                "Authorization":  "Bearer "+sessionStorage.getItem("token")

                }
          }).then((response)=>{
                                if(!response.ok){
                                    throw  new Error(`HTTP error! Status: ${response.status}`);
                                }
                                return response;
                            }
                 ).then((response)=>{
                        if(response.status=='201'){
                                    alert("Successfully updated");
                                    window.location.href =  "/dashboard";
                            }
                            if(response.status=='200'){
                                window.location.href =  "/dashboard";
                            }
                        }
                    );



      }
   
      if(sessionStorage.getItem("session")!="true"){
        window.location.href =  "/login";
        return <><h2>Please login...</h2></>;

      }else
return (
    
<form id="empform" onSubmit={handleSubmit}>
    <h2>Create Employee</h2>
    <label for="name">Name</label>
    <input type="text" name="name" placeholder="Name as per Aadhaar card "
        value={employee.name||""}
        onChange={handleChange}
    />

    <label for="name">Company</label>
    <input type="text" name="company" placeholder="Company name "
    value={employee.company||""} 
    onChange={handleChange}/>

    <label for="name">Salary</label>
    <input type="numeric" id="salary" name="salary" placeholder="Salary"
    value={employee.salary||""}
    onChange={handleChange}/>

    <input type="submit" value="Submit"></input>
  
</form>);

}

export default CreateEmployee;