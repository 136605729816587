import { useEffect, useState } from "react";
import './ViewEmployee.css';
import Deductions from "./Deductions.js";

function ViewEmployee({user}){

    const[viewEmployee, setViewemployee]=useState({});
    
    var id=window.location.href.split("?")[1].split("=")[1];
    const modal = document.getElementById("mydeductions");
    console.log(id);

    useEffect(()=>{
        async function getEmployee(){
            var resp=await fetch("employees/".concat(id),

            {
                method: "GET",
                headers:{
                        "Content-Type": "application/json",
                        "Accept":"*/*",
                        "Authorization":  "Bearer "+sessionStorage.getItem("token")
                        }
            }
            
            )
            
            
            .then((response)=>{
                if(!response.ok){
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response;
            });
            var body=await resp.json();
            setViewemployee(body);
            console.log( body);
        }
        getEmployee();



    },[]);

    const showDedcutions=()=>{
        document.getElementById("mydeductions").style.display="block";
    }


    // ========================================================================
    // =======================================================================

    if(sessionStorage.getItem("session")!="true"){
        window.location.href =  "/login";
        return <><h2>Please login...</h2></>;

      }else

    return(<>
    <h1>{viewEmployee.name}
    <button class="header-button" name="view" onClick={showDedcutions}>Add Deductions</button></h1>
    

    <table>
<tr>
    <td>ID:</td> <td>{viewEmployee.id}</td>
</tr>
<tr>
    <td>Company:</td> <td>{viewEmployee.company}</td>
</tr>
<tr>
    <td>Salary:</td> <td>{viewEmployee.salary}</td>
</tr>


    </table>

    <div id="mydeductions" class="modal">
          <div className="modal-content">
            <span ><p className="close"  onClick={()=>{modal.style.display="none";}}>&times;</p></span>
            <Deductions/>
          </div>
      </div>
    


    </>);
}

export default ViewEmployee;