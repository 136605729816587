import { Outlet, Link } from "react-router-dom";
import {Navbar, NavbarBrand} from 'reactstrap';
import  './Layout.css';

const Layout = () => {
  return (
    <>
      <ul class="navigation">
            <li class="navigation-list"><a href="/home">Home</a></li>
            <li class="navigation-list"><a href="/Services">Services</a></li>
            <li class="navigation-list"><a href="/about">About Jayadhee</a></li>
            {/* <li class="navigation-list"><a href="/career">Careers</a></li> */}
            <li class="navigation-list"><a href="/contact">Contact Us</a></li>
            <li class="navigation-list"><a href="/login">Login</a></li>
     </ul>
      

      <Outlet />
    </>
  )
};

export default Layout;