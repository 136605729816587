import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Dashboard from './Dashboard';
import Layout from "./Layout";
import AuthLayout from"./AuthLayout";
import Services from './Services';
import Contact from './Contact';
import Login from './Login';
import ViewEmployee from './ViewEmployee';
import Home from './Home';
import About from './About';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateEmployee from './CreateEmployee';
import Timesheet from './Timesheet';

import { useState, useRef} from 'react';
import Logout from './Logout';

export default function Apple() {
//const[viewemployee, setViewemployee] =useRef({});


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="logout" element={<Logout/>} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
        <Route path="/" element={<AuthLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="createEmployee" element={<CreateEmployee user ='{}' type='new'/>} />
          <Route path="timesheet" element={<Timesheet/>} />
          <Route path="employee" element={<ViewEmployee user ={''}/>} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Apple />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
