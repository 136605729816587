const Services = () => {
    return (
    
    <>
    <h1>Services</h1>
    <p>We help organizations with below services.</p>
      <ol>
      <li>Human Resources on temporary or permanent basis</li>
      <li>Recruitment of staff members</li>
      <li>Payroll management</li>
      <li>ESI and PF management</li>
      <li>Payroll management</li>
      </ol>
       </>
       );

  };
  
  export default Services;