
import './CreateEmployee.css';
function Deductions(){


    return (<>

<form id="empform" onSubmit="">
    <span><label for="name">Date</label>
    <input type="date" id="date" name="date" placeholder="Date"/>
    </span>
    <br/>

    <label for="name">Amount</label>
    <input type="number" id="amount" name="amount" placeholder="Amount"/>

    <br/>
    <label for="name">Remarks</label>
    <input type="text" id="remarks" name="remarks" placeholder="Remarks"/>
    <input type="submit" value="Submit"></input>
    </form>

    
    </>);


} 

export default Deductions;