const About = () => {
    return(

        <>
          <p>
        <br></br>
        &nbsp;&nbsp;Jayadhee consultancy is HR consulting partner who helps with human resources needs of companies from professional to unskilled helpers in various industries including pharma,
engineering, manufacturing, fabrication,housekeeping, retail stores, etc.<br></br><br></br>


&nbsp;&nbsp; We believe the success of any organization depends on vision, planning, action and the right people to achieve. We help companies with different categories of professional resources for their needs.
<br></br><br></br>

<b>Our Vision:</b><br></br> To provide employment to unemployed in various sectors by helping with
required skills for youth.<br></br><br></br> </p>
<b>Our values:</b><br></br>


<ol>
<li>To provide qualified and suitable resources for companies to see them as their future
employees</li>
<li>To adhere and comply with all legal requirements and policies involved with man
power and HR consulting.</li>
</ol>


<br></br>
<br></br>
   <p> We are one among the few good man power supply, HR consulting service providers, we
help with technical and non technical staff, helpers and below professionals:</p>

<ol>
<li>Welders</li>
<li>Gas cutters</li>
<li>Grinders</li>
<li>CNC operators</li>
<li>Fitters</li>
<li>Drivers</li>
<li>Helpers</li>
<li>Office staff</li>
</ol>

<br></br>
<br></br>


     We also offer freshers with SSC, Inter, ITI, Diploma, B.Sc/B.tech graduates for recruitments on contract or permanent basis requirements.

     <br></br>
     <br></br>
Currently, we are serving below companies:
<br></br>


<div>
<ol>
<li>Standard glass Pvt Ltd, Gagillapur</li>
<li>S2 Engineering Pvt Ltd, Gandimaisamma</li>
<li>Centuary Mattresses, Gagillapur</li>
<li>Tube investments of india-TIDC, Kazipally</li>
<li>New Aquaria electech Pvt Ltd, Kucharam</li>
<li>Mahsai profiles, Kucharam</li>
<li>Wonder company, Kucharam </li>
<li>H.S Containers, kucharam</li>
<li>Standard Flora, Nawabpet</li>
</ol>
</div>
        
        
        </>
    );
    
    
    
    
    
    
  };
  
  export default About;