const Contact = () => {
    return (
    
    <>
    <div>
    <p class="address">

      Email:info@jayadhee.com<br></br>
      Mobile: +91 8499947977<br></br>
      Office: 12-11-844, Warasiguda, Hyderabad - 62
    </p>
    </div>
    
    
    </>
    
    
    );
    
    
    
    
    
    
    
    
    <h1>Contact Me</h1>;
  };
  
  export default Contact;