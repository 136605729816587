import './Home.css';
import { useEffect } from 'react';
import image1 from'./images/image1.jpeg'
import image2 from'./images/image2.webp'
import image3 from'./images/image3.jpg'
import image4 from'./images/image4.webp'
import image5 from'./images/image5.webp'
import image6 from'./images/image6.jpeg'

const Home=()=>{
        useEffect(() => { 
                showSlides(); 
              }, []) 

        let slideIndex = 0;
        function showSlides() {
          let i;
          const slides = document.getElementsByClassName("mySlides");
          let dots = document.getElementsByClassName("dot");
          for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
          }
          slideIndex++;
          if (slideIndex > slides.length) {slideIndex = 1}    
          for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
          }
          slides[slideIndex-1].style.display = "block";  
          dots[slideIndex-1].className += " active";
          setTimeout(showSlides, 2000); // Change image every 2 seconds
        }
        //showSlides();

return (
<>

<div class="slideshow-container">

<div class="mySlides fade">
  <div class="numbertext">1 / 3</div>
  <img src={image2} width="100%" height="450"></img>
  <div class="text">Caption Text</div>
</div>

<div class="mySlides fade">
  <div class="numbertext">2 / 3</div>
  <img src={image4}  width="100%" height="450"></img>
  <div class="text">Caption Two</div>
</div>

<div class="mySlides fade">
  <div class="numbertext">3 / 3</div>
  <img src={image5} alt ="image5" width="100%" height="450"></img>
  <div class="text">Caption Three</div>
</div>




<div class="dots-list" >
  <span class="dot"></span> 
  <span class="dot"></span> 
  <span class="dot"></span> 
  </div>
</div>

<div display="block">
        <img src={image5} alt ="image" width="2000" height="500" ></img>
        <img src={image2} alt ="image" width="2000" height="500" ></img>
        {/* <img src={image3} alt ="image" width="3000" height="500" ></img> */}
        {/* <img src={image4} alt ="image" width="3000" height="500" ></img>
        <img src={image1} alt ="image" width="3000" height="500" ></img>
        <img src={image6} alt ="image" width="3000" height="500" ></img> */}
</div>
  
      <div class="relative-div">
      

<footer>
    <div class="footer_container">
        <div class="copy_text">© 2024 Jayadhee </div>
        <div class="ft_links">
            <a target="_blank" href="/disclaimer/">Disclaimer</a>
        
            <a target="_blank" href="/privacy-statement/">Privacy</a>
        </div>
        {/* <div class="ft_social_links">

            <a target="_blank" href="https://www.facebook.com/WiproLimited/" class="social-image" rel="nofollow">
                
            </a>

        

            <a target="_blank" href="https://www.instagram.com/wiprolimited" class="social-image" rel="nofollow">
                
            </a>

        

            <a target="_self" href="https://www.linkedin.com/company/wipro" class="social-image" rel="nofollow">
           
            </a>

        

            <a target="_blank" href="https://www.youtube.com/user/Wiprovideos" class="social-image" rel="nofollow">
                
            </a>

        </div> */}
    </div>
</footer>
</div>


       

        </>);
};
export default Home;