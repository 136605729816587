import './Login.css';
import {useState, useEffect} from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';

function Login()
    {

        const [loginuser,setLoginuser] =useState({});
        const [email, setEmail] = useState(''); 
        const [password, setPassword] = useState(''); 
        const [error, setError] = useState('');
        const history = useNavigate(); 
        let token="";

        const handleChange = (event) => {
            const name = event.target.name;
            const value = event.target.value;
            setLoginuser(values => ({...values, [name]: value}));
        }    
        const handleLogin = async (event) =>
                            {
                                event.preventDefault();
                                console.log(JSON.stringify(loginuser));
                                const response = await fetch('/auth/signin',
                                    {
                                        method: "POST",
                                        body:JSON.stringify({
                                            
                                            "email": email,
                                            "password": password,
                                            "username": email,
                                            "name":email
                                            
                                        }),
                                        headers:{
                                            "Content-Type": "application/json",
                                            "Accept":"*/*"
                                            }
                                });
                                
                                
                                    switch (response.status) {
                                        case 200:
                                            const tokeninrsp=await response.json().then(data=>{ return data.jwt; });
                                            sessionStorage.setItem("token",tokeninrsp);
                                            sessionStorage.setItem("session","true");

                                           // console.log("session"+sessionStorage.getItem("token"));
                                            window.location.href =  "/dashboard";   
                                            break;
                                        case 403:
                                            setError("Forbidden...");
                                            break;
                                        case 401:
                                            setError("Unauthorised...");
                                            break;
                                    
                                        default:
                                            setError("login failed...");
                                            break;
                                    }
                               
                                      
                            } 
                            
                            
    

    return(
            <>
                <form id="loginform" >
                    <label for="name">Username</label>
                    <input type="text" id="email" name="email"  value={email} placeholder="Enter username" onChange={(e) => setEmail(e.target.value)}/>
                    <label for="name">Password</label>
                    <input type="password" id="password" name="password" value={password} placeholder="Enter password" onChange={(e) => setPassword(e.target.value)}/>
                    <input type="submit" value="Login" onClick={handleLogin}></input>
                    {error && <p className="text-danger">{error}</p>} {/* Render error message if exists */} 
                </form>
            </>
        );
    }

export default Login;